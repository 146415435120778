import React from 'react';

const ReportsPage = () => {
  console.log('ReportsPage is rendering');
  return (
    <div>
      <h1>Reports Page</h1>
      {/* Additional content can be added here */}
    </div>
  );
};

export default ReportsPage;
