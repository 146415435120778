import React from 'react';

const UsersPage = () => {
  console.log('UsersPage is rendering');
  return (
    <div>
      <h1>Users Page</h1>
      {/* Additional content can be added here */}
    </div>
  );
};

export default UsersPage;
